import type { Media } from '@/types/media';
import type { BaseUser } from '@/types/users';
import type { ApiTransactionTask } from './transactions';

interface ApiTask {
	attachments: (Media & { uploaded_by: BaseUser })[];
	completed_at: string | null;
	completed_by: {
		first_name: string;
		last_name: string;
		uuid: Uuid;
	} | null;
	created_at: string;
	description: string;
	due_at: string | null;
	link: string | null;
	name: string;
	uuid: Uuid;
}

interface ApiUpdateTaskModel {
	attachments?: {
		fileName: string;
		key: string;
	}[];
	is_complete?: boolean;
	link?: string;
}

class Task {
	attachments: Media[];
	completedAt: string | null;
	completedBy: string;
	description: string | null;
	dueAt: string | null;
	isComplete: boolean;
	link: string | null;
	name: string;
	uuid: Uuid;

	constructor(data: ApiTask | ApiTransactionTask) {
		this.attachments = data.attachments ?? [];
		this.completedAt = data.completed_at;
		this.completedBy = data.completed_by ? `${data.completed_by.first_name} ${data.completed_by.last_name}` : '';
		this.description = data.description ?? null;
		this.dueAt = 'due_at' in data
			? data.due_at
			: data.due_date ?? null;
		this.isComplete = data.completed_at !== null;
		this.link = data.link;
		this.name = data.name;
		this.uuid = data.uuid;
	}
}

export {
	type ApiTask,
	type ApiUpdateTaskModel,
	Task,
};
