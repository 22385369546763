import type { RouteRecordRaw } from 'vue-router';
import { useMainStore } from '@/stores/mainStore';
import { useUserStore } from '@/stores/userStore';
import { useUserListsStore } from '@/stores/userListsStore';

const routes: RouteRecordRaw[] = [
	{
		path: '/exchange',
		children: [
			{
				path: 'athletes',
				name: 'ExchangeAthletes',
				component: () => import('@/views/ExchangeAthletesParent.vue'),
				redirect: { name: 'ExchangeAthletesBrowse' },
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageExchange.metaTitle',
					requiredAccess: 'hasExchangeWrite',
				},
				children: [
					{
						path: 'browse',
						name: 'ExchangeAthletesBrowse',
						component: () => import('@/views/ExchangeAthletesBrowse.vue'),
						meta: {
							requiresAuth: true,
							layout: 'LayoutAuthenticated',
							title: 'pageExchangeAthletesBrowse.metaTitle',
							requiredAccess: 'hasExchangeWrite',
						},
						beforeEnter: [setSchools, setConferences, setUserLists],
					},
					{
						path: 'saved-lists',
						name: 'ExchangeUserLists',
						meta: {
							requiresAuth: true,
							layout: 'LayoutAuthenticated',
							title: 'pageExchangeUserLists.metaTitle',
							requiredAccess: 'hasExchangeWrite',
						},
						component: () => import('@/views/ExchangeUserLists.vue'),
					},
				],
			},
			{
				path: 'athletes/:athleteUuid',
				name: 'ExchangeAthlete',
				component: () => import('@/views/ExchangeAthlete.vue'),
				props: true,
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageExchangeAthlete.metaTitle',
					requiredAccess: 'hasExchangeWrite',
				},
			},
			{
				path: 'conversations',
				name: 'ExchangeConversations',
				component: () => import('@/views/ExchangeConversations.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageExchangeConversations.metaTitle',
					requiredAccess: 'hasExchangeWrite',
				},
				beforeEnter: [setSchools],
				children: [
					{
						path: ':conversationUuid',
						name: 'ExchangeConversation',
						component: () => import('@/views/ExchangeConversation.vue'),
						props: true,
						meta: {
							requiresAuth: true,
							layout: 'LayoutAuthenticated',
							title: 'pageExchangeConversations.metaTitle',
							requiredAccess: 'hasExchangeWrite',
							profileSwitchRedirect: 'ExchangeConversations',
						},
					},
				],
			},
			{
				path: 'bulletins',
				name: 'ExchangeBulletins',
				component: () => import('@/views/ExchangeBulletins.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageExchangeBulletins.metaTitle',
					requiredAccess: 'canAccessBulletins',
				},
			},
			{
				path: 'bulletins/:bulletinUuid',
				name: 'ExchangeBulletin',
				component: () => import('@/views/ExchangeBulletinParent.vue'),
				props: true,
				redirect: { name: 'ExchangeBulletinDetails' },
				children: [
					{
						path: 'details',
						name: 'ExchangeBulletinDetails',
						props: true,
						component: () => import('@/views/ExchangeBulletinDetails.vue'),
						meta: {
							requiresAuth: true,
							layout: 'LayoutAuthenticated',
							title: 'pageExchangeBulletinDetails.metaTitle',
							requiredAccess: 'canAccessBulletins',
							profileSwitchRedirect: 'ExchangeBulletins',
						},
					},
					{
						path: 'applications',
						name: 'ExchangeBulletinApplicants',
						props: true,
						component: () => import('@/views/ExchangeBulletinApplicants.vue'),
						meta: {
							requiresAuth: true,
							layout: 'LayoutAuthenticated',
							title: 'pageExchangeBulletinApplicants.metaTitle',
							requiredAccess: 'canAccessBulletins',
							profileSwitchRedirect: 'ExchangeBulletins',
						},
					},
				],
			},
			{
				path: 'transactions',
				name: 'ExchangeTransactionsParent',
				component: () => import('@/views/ExchangeTransactionsParent.vue'),
				beforeEnter: () => {
					const userStore = useUserStore();
					if (userStore.profileAccess.hasAgentWrite) return;
					Promise.all([setSchools(), setConferences()]);
				},
				children: [
					{
						path: '',
						name: 'ExchangeTransactions',
						component: () => import('@/views/ExchangeTransactions.vue'),
						meta: {
							requiresAuth: true,
							layout: 'LayoutAuthenticated',
							title: 'pageExchangeTransactions.metaTitle',
							requiredAccess: 'canAccessTransactions',
						},
					},
					{
						path: 'leaderboard',
						name: 'ExchangeTransactionsLeaderboard',
						component: () => import('@/views/ExchangeTransactionsLeaderboard.vue'),
						meta: {
							requiresAuth: true,
							layout: 'LayoutAuthenticated',
							title: 'pageExchangeTransactionsLeaderboard.metaTitle',
							requiredAccess: 'canAccessTransactions',
						},
						beforeEnter: [checkForLeaderboard],
					},
				],
			},
			{
				path: 'transactions/:transactionUuid',
				name: 'ExchangeTransaction',
				component: () => import('@/views/ExchangeTransaction.vue'),
				props: true,
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageTransaction.metaTitle',
					requiredAccess: 'canAccessTransactions',
					profileSwitchRedirect: 'ExchangeTransactions',
				},
			},
			{
				path: 'agreements',
				name: 'ExchangeAgreements',
				redirect: { name: 'ExchangeTransactions' },
			},
			{
				path: 'agreements/:agreementUuid',
				name: 'ExchangeAgreement',
				component: () => import('@/views/ExchangeAgreement.vue'),
				props: true,
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageAgreement.metaTitle',
					requiredAccess: 'canAccessTransactions',
				},
			},
			/* {
				path: 'industry-values',
				name: 'ExchangeIndustryValues',
				component: () => import('@/views/ExchangeIndustryValues.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageExchangeIndustryValues.metaTitle',
					requiredAccess: 'hasExchangeWrite',
				},
			}, */
			{
				path: 'settings',
				name: 'ExchangeSettings',
				component: () => import('@/views/ExchangeSettingsParent.vue'),
				redirect: { name: 'ExchangeSettingsProfile' },
				children: [
					{
						path: 'profile',
						name: 'ExchangeSettingsProfile',
						component: () => import('@/views/ExchangeSettingsProfile.vue'),
						meta: {
							requiresAuth: true,
							layout: 'LayoutAuthenticated',
							title: 'pageExchangeSettingsProfile.metaTitle',
							requiredAccess: 'hasExchangeWrite',
						},
					},
					{
						path: 'payment-methods',
						name: 'ExchangeSettingsPaymentMethods',
						component: () => import('@/views/ExchangeSettingsPaymentMethods.vue'),
						meta: {
							requiresAuth: true,
							layout: 'LayoutAuthenticated',
							title: 'pageExchangeSettingsPaymentMethods.metaTitle',
							requiredAccess: 'hasExchangeWrite',
						},
					}, {
						path: 'members',
						name: 'ExchangeSettingsMembers',
						component: () => import('@/views/ExchangeSettingsMembers.vue'),
						meta: {
							requiresAuth: true,
							layout: 'LayoutAuthenticated',
							title: 'pageExchangeSettingsMembers.metaTitle',
							requiredAccess: 'isExchangeBrandAdmin',
						},
					},
				],
			},
		],
	},
	{
		path: '/exchange/:schoolSlug',
		name: 'ExchangeSchoolSite',
		component: () => import('@/views/ExchangeSchoolSiteParent.vue'),
		props: true,
		children: [
			{
				path: '',
				name: 'ExchangeSchoolSiteIndex',
				component: () => import('@/views/ExchangeSchoolSiteIndex.vue'),
				meta: {
					requiresAuth: false,
					layout: 'LayoutExchangeSchoolSite',
					title: 'exchangeSchoolSite.metaTitle',
				},
			},
			{
				path: 'learn-more',
				name: 'ExchangeSchoolSiteLearnMore',
				component: () => import('@/views/ExchangeSchoolSiteLearnMore.vue'),
				meta: {
					requiresAuth: false,
					layout: 'LayoutExchangeSchoolSite',
					title: 'exchangeSchoolSite.metaTitle',
				},
			},
			{
				path: 'registration',
				name: 'ExchangeSchoolSiteRegistration',
				component: () => import('@/views/ExchangeSchoolSiteRegistration.vue'),
				meta: {
					requiresAuth: false,
					layout: 'LayoutExchangeSchoolSite',
					title: 'exchangeSchoolSite.metaTitle',
				},
			},
		],
	},
];

async function setSchools() {
	const mainStore = useMainStore();
	if (mainStore.schools.length) return;
	try {
		await mainStore.setSchools();
	} catch (e) {
		console.error(e);
	}
}

async function setConferences() {
	const mainStore = useMainStore();
	if (mainStore.conferences.length) return;
	try {
		await mainStore.setConferences();
	} catch (e) {
		console.error(e);
	}
}

async function setUserLists() {
	const userListsStore = useUserListsStore();
	if (userListsStore.userLists.length) return;
	try {
		await userListsStore.setUserLists();
	} catch (e) {
		console.error(e);
	}
}

function checkForLeaderboard() {
	const userStore = useUserStore();
	if (!userStore.leaderboardEnabled) {
		return { name: 'NotFound' };
	}
}

export default routes;
