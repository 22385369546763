import 'vuetify/styles';
import '@material-design-icons/font/round.css';
import { createVuetify, type IconProps, type ThemeDefinition } from 'vuetify';
import { aliases } from 'vuetify/iconsets/md';
import IconInflcrHex from '@/components/icon/IconInflcrHex.vue';
import IconNotemeal from '@/components/icon/IconNotemeal.vue';
import IconTeamworks from '@/components/icon/IconTeamworks.vue';
import IconWhistle from '@/components/icon/IconWhistle.vue';
import IconConversation from '@/components/icon/IconConversation.vue';
import IconDefaultAvatar from '@/components/icon/IconDefaultAvatar.vue';
import IconChatSupport from '@/components/icon/IconChatSupport.vue';
import IconFacebook from '@/components/icon/IconFacebook.vue';
import IconInfluential from '@/components/icon/IconInfluential.vue';
import IconInstagram from '@/components/icon/IconInstagram.vue';
import IconLinkedIn from '@/components/icon/IconLinkedIn.vue';
import IconMessagesDollar from '@/components/icon/IconMessagesDollar.vue';
import IconOn3 from '@/components/icon/IconOn3.vue';
import IconTikTok from '@/components/icon/IconTikTok.vue';
import IconX from '@/components/icon/IconX.vue';
import IconVectorUp from '@/components/icon/IconVectorUp.vue';
import IconVectorDown from '@/components/icon/IconVectorDown.vue';
import IconAmex from '@/components/icon/IconAmex.vue';
import IconDinersClub from '@/components/icon/IconDinersClub.vue';
import IconDiscover from '@/components/icon/IconDiscover.vue';
import IconJCB from '@/components/icon/IconJCB.vue';
import IconMastercard from '@/components/icon/IconMastercard.vue';
import IconVisa from '@/components/icon/IconVisa.vue';
import IconBankTransfer from '@/components/icon/IconBankTransfer.vue';
import IconAppAcademics from '@/components/icon/IconAppAcademics.vue';
import IconAppAMS from '@/components/icon/IconAppAMS.vue';
import IconAppCamps from '@/components/icon/IconAppCamps.vue';
import IconAppCollectives from '@/components/icon/IconAppCollectives.vue';
import IconAppCommunities from '@/components/icon/IconAppCommunities.vue';
import IconAppCompliance from '@/components/icon/IconAppCompliance.vue';
import IconAppHub from '@/components/icon/IconAppHub.vue';
import IconAppInfluencer from '@/components/icon/IconAppInfluencer.vue';
import IconAppNutrition from '@/components/icon/IconAppNutrition.vue';
import IconAppPathways from '@/components/icon/IconAppPathways.vue';
import IconAppPulse from '@/components/icon/IconAppPulse.vue';
import IconAppRCommunication from '@/components/icon/IconAppRCommunication.vue';
import IconAppRecruiting from '@/components/icon/IconAppRecruiting.vue';
import IconAppStrength from '@/components/icon/IconAppStrength.vue';
import IconAppSwitcher from '@/components/icon/IconAppSwitcher.vue';
import IconAppTeamworks from '@/components/icon/IconAppTeamworks.vue';
import IconAppTrac from '@/components/icon/IconAppTrac.vue';
import IconAppWallet from '@/components/icon/IconAppWallet.vue';

import { VLigatureIcon } from 'vuetify/lib/components/index.mjs';
import { VAutocomplete } from 'vuetify/components/VAutocomplete';
import { VBtn } from 'vuetify/components/VBtn';
import { VChip } from 'vuetify/components/VChip';
import { VList } from 'vuetify/components/VList';
import { VTabs } from 'vuetify/components/VTabs';

import { lightDefaultHex, darkDefaultHex } from '@/utils/colorContrast';
import { en, fr, pt, it, es, de } from 'vuetify/locale';
import { h } from 'vue';


const INFLCRLight: ThemeDefinition = {
	dark: false,
	colors: {
		background: '#ffffff',
		surface: '#f4f5f6',
		primary: '#2cc3f2',
		'primary-button': '#000000',
		error: '#d83d32',
		info: '#045dff',
		success: '#0a893c',
		'success-25': '#e7f3ec',
		warning: '#f79009',
		'border-light': '#d0d5dd',
		'border-dark': '#414445',
		anchor: '#000000',
		purple: '#7f56d9',
		'neutral-25': '#fcfcfc',
		'neutral-50': '#f8fafb',
		'neutral-100': '#f2f4f7',
		'neutral-200': '#eaecf0',
		'neutral-300': '#d0d5dd',
		'neutral-400': '#98a2b3',
		'neutral-500': '#667085',
		'neutral-600': '#475467',
		'neutral-700': '#344054',
		'neutral-800': '#1d2939',
		'neutral-900': '#101828',
		'info-25': '#e6efff',
		'warning-25': '#fef4e6',
		'brands-facebook': '#0866ff',
		'brands-influential': '#1866fe',
		'brands-instagram': '#fc3857',
		'brands-linkedin': '#0966c2',
		'brands-on3': '#f93b1d',
		'brands-tiktok': '#000000',
		'brands-x': '#000000',
		'brands-amex': '#2671b9',
		'brands-diners': '#006699',
		'brands-discover': '#e55C20',
		'brands-jcb': '#0C4496',
		'brands-mastercard': '#eb001b',
		'brands-visa': '#1434cb',
		'axle-navbar-top': '#000000',
		'axle-navbar-bottom': '#2CC3F2',
		'school-primary': darkDefaultHex,
		'school-background': lightDefaultHex,
		'school-header-background': darkDefaultHex,
		'fee-type-hourly': '#E8F0FF',
		'fee-type-fixed-cost': '#F6FBED',
		'fee-type-percent-of-deals': '#F3EFFA',
		'fee-type-negotiated': '#FFE8DB',
	},
};

const INFLCRDark: ThemeDefinition = {
	dark: true,
	colors: {
		background: '#000000',
		surface: '#121516',
		primary: '#2cc3f2',
		'primary-button': '#ffffff',
		error: '#d83d32',
		info: '#045dff',
		success: '#0a893c',
		warning: '#f79009',
		'border-light': '#d0d5dd',
		'border-dark': '#414445',
		anchor: '#000000',
		purple: '#7f56d9',
		'neutral-25': '#e7e8e8',
		'neutral-50': '#d0d0d0',
		'neutral-100': '#b8b9b9',
		'neutral-200': '#a0a1a2',
		'neutral-300': '#898a8b',
		'neutral-400': '#717373',
		'neutral-500': '#595b5c',
		'neutral-600': '#414445',
		'neutral-700': '#2a2c2d',
		'neutral-800': '#121516',
		'neutral-900': '#0b0d0d',
		'warning-25': '#fef4e6',
		'brands-facebook': '#0866ff',
		'brands-influential': '#1866fe',
		'brands-instagram': '#fc3857',
		'brands-linkedin': '#0966c2',
		'brands-on3': '#f93b1d',
		'brands-tiktok': '#000000',
		'brands-x': '#000000',
		'brands-amex': '#2671b9',
		'brands-diners': '#006699',
		'brands-discover': '#e55C20',
		'brands-jcb': '#0C4496',
		'brands-mastercard': '#eb001b',
		'brands-visa': '#1434cb',
		'axle-navbar-top': '#000000',
		'axle-navbar-bottom': '#2CC3F2',
		'school-primary': darkDefaultHex,
		'school-background': lightDefaultHex,
		'school-header-background': darkDefaultHex,
		'fee-type-hourly': '#E8F0FF',
		'fee-type-fixed-cost': '#F6FBED',
		'fee-type-percent-of-deals': '#F3EFFA',
		'fee-type-negotiated': '#FFE8DB',
	},
};

export const inflcrDefaults = {
	VChipMini: {
		border: true,
		class: ['v-chip-mini', 'text-caption', 'font-weight-medium', 'px-1'],
		closeIcon: 'close',
		size: 'x-small',
		label: true,
		style: ['--v-activated-opacity: 0.03'],
	},
	VBtnPrimary: {
		color: 'primary-button',
		elevation: 1,
		variant: 'flat',
	} as VBtn['$props'],
	VBtnSecondary: {
		border: 'sm solid border-light opacity-100',
		class: 'text-decoration-none bg-background',
		elevation: 1,
		variant: 'outlined',
	} as VBtn['$props'],
	VBtnDanger: {
		color: 'error',
		elevation: 1,
		variant: 'outlined',
	} as VBtn['$props'],
	VBtnText: {
		size: 'small',
		variant: 'text',
	} as VBtn['$props'],
};

const vuetify = createVuetify({
	display: {
		mobileBreakpoint: 'md',
	},
	theme: {
		defaultTheme: 'INFLCRLight',
		themes: {
			INFLCRLight,
			INFLCRDark,
		},
	},
	icons: {
		defaultSet: 'md',
		aliases: {
			...aliases,
			'twi-inflcr-hex': IconInflcrHex,
			'twi-notemeal': IconNotemeal,
			'twi-teamworks': IconTeamworks,
			'twi-whistle': IconWhistle,
			'twi-conversation': IconConversation,
			'twi-avatar': IconDefaultAvatar,
			error: 'error_outline',
			'twi-chat-support': IconChatSupport,
			'twi-facebook': IconFacebook,
			'twi-influential': IconInfluential,
			'twi-instagram': IconInstagram,
			'twi-linkedin': IconLinkedIn,
			'twi-messages-dollar': IconMessagesDollar,
			'twi-on3': IconOn3,
			'twi-tiktok': IconTikTok,
			'twi-x': IconX,
			'twi-vector-up': IconVectorUp,
			'twi-vector-down': IconVectorDown,
			'twi-amex': IconAmex,
			'twi-diners': IconDinersClub,
			'twi-discover': IconDiscover,
			'twi-jcb': IconJCB,
			'twi-mastercard': IconMastercard,
			'twi-visa': IconVisa,
			'twi-bank-transfer': IconBankTransfer,
			'twi-app-academics': IconAppAcademics,
			'twi-app-ams': IconAppAMS,
			'twi-app-camps': IconAppCamps,
			'twi-app-collectives': IconAppCollectives,
			'twi-app-communities': IconAppCommunities,
			'twi-app-compliance': IconAppCompliance,
			'twi-app-hub': IconAppHub,
			'twi-app-influencer': IconAppInfluencer,
			'twi-app-nutrition': IconAppNutrition,
			'twi-app-pathways': IconAppPathways,
			'twi-app-pulse': IconAppPulse,
			'twi-app-r-communication': IconAppRCommunication,
			'twi-app-recruiting': IconAppRecruiting,
			'twi-app-strength': IconAppStrength,
			'twi-app-switcher': IconAppSwitcher,
			'twi-app-teamworks': IconAppTeamworks,
			'twi-app-trac': IconAppTrac,
			'twi-app-wallet': IconAppWallet,
		},
		sets: {
			md: {
				component: (props: IconProps) => h(VLigatureIcon, {
					...props,
					class: 'material-icons-round',
				}),
			},
		},
	},
	aliases: {
		VAutocompleteMulti: VAutocomplete,
		VBtnPrimary: VBtn,
		VBtnSecondary: VBtn,
		VBtnSchool: VBtn,
		VBtnDanger: VBtn,
		VBtnText: VBtn,
		VBtnTileSecondary: VBtn,
		VBtnIcon: VBtn,
		VBtnEditorTool: VBtn,
		VChipTag: VChip,
		VChipMini: VChip,
		VChipCategory: VChip,
		VMenuList: VList,
		VAthleteList: VList,
		VTabsNavigation: VTabs,
	},
	defaults: {
		VAutocomplete: {
			density: 'comfortable',
			variant: 'outlined',
			menuIcon: 'expand_more',
			persistentClear: true,
			VMenu: {
				offset: 0,
			},
		},
		VAutocompleteMulti: {
			class: 'v-autocomplete-multi border rounded',
			density: 'comfortable',
			variant: 'outlined',
			menuIcon: 'expand_more',
			persistentClear: true,
			chips: true,
			closableChips: true,
			multiple: true,
			VChip: {
				...inflcrDefaults.VChipMini,
				class: [...inflcrDefaults.VChipMini.class, 'pr-2'],
				style: [...inflcrDefaults.VChipMini.style, '--v-chip-height: 24px'],
			},
			VMenu: {
				offset: 0,
			},
		},
		VAlert: {
			variant: 'tonal',
		},
		VBtn: {
			class: 'text-decoration-none',
		},
		VBtnPrimary: inflcrDefaults.VBtnPrimary,
		VBtnSchool: { ...inflcrDefaults.VBtnPrimary, color: 'school-primary' },
		VBtnSecondary: inflcrDefaults.VBtnSecondary,
		VBtnDanger: inflcrDefaults.VBtnDanger,
		VBtnText: inflcrDefaults.VBtnText,
		VBtnTileSecondary: {
			border: 'sm solid border-light opacity-100',
			class: 'px-0',
			elevation: 1,
			variant: 'outlined',
			style: { minWidth: 'var(--v-btn-height)' },
		},
		VBtnIcon: {
			class: 'px-0',
			style: { minWidth: 'var(--v-btn-height)' },
			variant: 'text',
			rounded: true,
		},
		VBtnEditorTool: {
			class: 'v-btn-editor-tool pa-0',
			ripple: false,
			variant: 'text',
			style: [
				'--v-btn-height: 28px',
				{ minWidth: 'var(--v-btn-height)' },
			],
			VIcon: {
				size: 16,
			},
		},
		VChip: {
			class: 'flex-0-0',
			variant: 'flat',
		},
		VChipMini: inflcrDefaults.VChipMini,
		VChipTag: {
			color: 'white',
			elevation: 1,
			label: true,
			ripple: false,
			variant: 'elevated',
		},
		VChipCategory: {
			closeIcon: 'close',
			density: 'compact',
			variant: 'outlined',
			class: 'px-2',
			style: { borderColor: 'rgb(var(--v-theme-border-light))' },
		},
		VCombobox: {
			density: 'comfortable',
			variant: 'outlined',
			menuIcon: 'expand_more',
			persistentClear: true,
			chips: true,
			closableChips: true,
			multiple: true,
			VChip: {
				...inflcrDefaults.VChipMini,
				class: [...inflcrDefaults.VChipMini.class, 'pr-2'],
				style: [...inflcrDefaults.VChipMini.style, '--v-chip-height: 24px'],
			},
			VMenu: {
				offset: 0,
			},
		},
		VDialog: {
			origin: 'center center',
			transition: 'fade-transition',
			zIndex: 1000000,
		},
		VListItemSubtitle: {
			class: 'text-caption font-weight-medium',
		},
		VMenuList: {
			nav: true,
			minWidth: 240,
			bgColor: 'background',
			density: 'compact',
		},
		VAthleteList: {
			bgColor: 'background',
			lines: false,
			VListItemSpacer: {
				class: 'mr-2',
			},
			VListItem: {
				class: 'py-2',
			},
			VListItemTitle: {
				class: 'text-body-2 font-weight-semibold',
			},
			VListItemSubtitle: {
				class: 'text-body-2 font-weight-regular',
			},
		},
		VDataTableServer: {
			class: 'border rounded bg-background',
			mustSort: true,
			sortAscIcon: 'arrow_drop_up',
			sortDescIcon: 'arrow_drop_down',
		},
		VIcon: {
			size: '20',
		},
		VMenu: {
			offset: 4,
			zIndex: 1000001,
		},
		VPagination: {
			density: 'compact',
			nextIcon: 'arrow_forward',
			prevIcon: 'arrow_back',
			totalVisible: 5,
		},
		VProgressCircular: {
			color: 'primary',
			indeterminate: true,
		},
		VProgressLinear: {
			color: 'primary',
			indeterminate: true,
			rounded: true,
		},
		VSelect: {
			density: 'comfortable',
			variant: 'outlined',
			menuIcon: 'expand_more',
			persistentClear: true,
			VMenu: {
				offset: 0,
			},
		},
		VStepperItem: {
			color: 'neutral-500',
			completeIcon: 'check_circle',
			icon: 'radio_button_unchecked',
			VAvatar: {
				variant: 'outlined',
				color: 'neutral-500',
				size: '28px',
			},
		},
		VTextarea: {
			color: 'border-light',
			density: 'comfortable',
			variant: 'outlined',
		},
		VSheet: {
			color: 'background',
		},
		VSwitch: {
			inset: true,
		},
		VTabs: {
			bgColor: 'neutral-200',
			class: 'border rounded',
			height: 42,
			VTab: {
				hideSlider: true,
				selectedClass: 'bg-neutral-50 elevation-1',
			},
		},
		VTabsNavigation: {
			height: 42,
		},
		VTextField: {
			color: 'border-light',
			density: 'comfortable',
			variant: 'outlined',
			hideSpinButtons: true,
		},
		VTimeline: {
			density: 'compact',
			lineThickness: '1',
			side: 'end',
			truncateLine: 'both',
			VTimelineItem: {
				dotColor: 'background',
				iconColor: 'info',
			},
		},
		VTooltip: {
			contentClass: 'elevation-2',
			maxWidth: 250,
		},
	},
	locale: {
		locale: 'en-US',
		fallback: 'en-US',
		messages: { 'en-US': en, 'fr-FR': fr, 'pt-BR': pt, 'it-IT': it, 'es-ES': es, 'de-DE': de },
	},
});

export { vuetify };
