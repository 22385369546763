<template>
	<AppSwitcherTheme />
	<div class="layout-authenticated">
		<TheNavigationMenu
			v-model="isNavigationMenuActive"
			class="no-print"
		/>
		<h1
			v-if="!showAppBar && !mobile"
			class="d-sr-only"
		>
			{{ pageGroupTitleState.title }}
		</h1>
		<VAppBar
			v-if="showAppBar || mobile"
			:class="[
				'layout-authenticated__app-bar no-print transition-none',
				!mobile && 'layout-authenticated__app-bar--desktop',
			]"
			color="axle-navbar-top"
			:height="headerHeight"
			flat
		>
			<div
				:class="[
					'layout-authenticated__app-bar-container bg-background w-100 px-4 pb-md-4 px-md-6',
					!mobile && 'mt-2 layout-authenticated__app-bar-container--rounded',
				]"
			>
				<CommonBreadcrumbs
					v-if="!mobile && isBreadcrumbsVisible"
					:items="breadcrumbItems"
				/>
				<div
					class="w-100 d-flex align-center align-self-stretch"
				>
					<VBtn
						v-if="mobile"
						class="mr-2"
						icon
						:aria-label="t('navigationMenu.expandMenu')"
						@click="isNavigationMenuActive = !isNavigationMenuActive"
					>
						<VIcon icon="menu" />
					</VBtn>
					<h1
						v-if="!!pageGroupTitleState.title"
						class="text-h1 text-truncate"
						data-testid="elementPageTitle"
					>
						{{ pageGroupTitleState.title }}
					</h1>
					<VSpacer />
					<VBtnSecondary
						v-if="!!userStore.impersonatedByProfile && !mobile"
						color="warning"
						data-testid="buttonLogoutImpersonation"
						:border="'sm solid warning opacity-100'"
						prepend-icon="co_present"
						@click="logOutImpersonation"
					>
						{{ t('returnTo', {name: impersonatedByName }) }}
					</VBtnSecondary>
					<VBtn
						v-if="!!userStore.impersonatedByProfile && mobile"
						color="warning"
						data-testid="buttonLogoutImpersonationMobile"
						icon
						:aria-label="t('logOut')"
						@click="logOutImpersonation"
					>
						<VIcon icon="co_present" />
					</VBtn>
					<CommonContextMenu
						ref="actionsMenu"
						activator-id="quickActionsMenu"
						:items="quickActionsMenuItems"
					>
						<template #activator>
							<VBtnPrimary
								id="quickActionsMenu"
								class="ml-2"
								prepend-icon="add"
								data-testid="buttonQuickActionsMenu"
							>
								{{ t('new') }}
							</VBtnPrimary>
						</template>
					</CommonContextMenu>
				</div>
			</div>
		</VAppBar>
		<VMain class="layout-authenticated__main transition-none">
			<slot :key="profileSwitchKey" />
		</VMain>
		<CrudGalleryDrawer
			v-if="isCrudGalleryDrawerRendered"
			v-model="crudGalleryDrawerState.isOpen"
			:gallery="crudGalleryDrawerState.selectedGallery"
			@updated-gallery="crudGalleryDrawerState.onUpdateSuccess"
			@deleted-gallery="crudGalleryDrawerState.onDeleteSuccess"
		/>
		<BulletinCrudModal
			v-if="isBulletinCrudModalRendered"
			v-model="bulletinState.isOpen"
			:bulletin="bulletinState.activeBulletin"
			:selected-athletes="bulletinState.selectedAthletes"
			@updated-bulletin="bulletinState.onBulletinUpdated"
		/>
		<ExchangeUserListCreateDrawer
			v-if="isExchangeUserListCreateDrawerRendered"
			v-model="exchangeUserListState.isOpen"
			:selected-users="exchangeUserListState.selectedUsers"
		/>
		<AgreementDialog
			v-if="isAgreementDialogRendered"
			v-model="agreementDialogState.isOpen"
			v-model:rate-card="agreementDialogState.activeRateCard"
			:bulletin-uuid="agreementDialogState.activeBulletinUuid"
			:selected-athletes="agreementDialogState.selectedAthletes"
			:agreement-uuid="agreementDialogState.agreementUuid"
			:is-duplicating="agreementDialogState.isDuplicating"
			:is-editing="agreementDialogState.isEditing"
			@created="agreementDialogState.onCreated"
			@updated="agreementDialogState.onUpdated"
		/>
		<TransactionProposeDialog
			v-if="isTransactionProposeDialogRendered"
			v-model="transactionState.isOpen"
			v-model:rate-card="transactionState.activeRateCard"
			:bulletin-uuid="transactionState.activeBulletinUuid"
			:is-editing="transactionState.isEditing"
			:selected-athletes="transactionState.selectedAthletesUuids"
			:transaction-uuid="transactionState.activeTransactionUuid"
			@success="transactionState.onSuccess"
			@updated="transactionState.onUpdated"
		/>
		<TransactionDrawer
			v-if="isTransactionDrawerRendered"
			v-model="transactionDrawerState.isOpen"
			:transaction-uuid="transactionDrawerState.activeTransactionUuid"
			:reviews-remaining="transactionDrawerState.reviewsRemaining"
			@agreement-archived="transactionDrawerState.onAgreementArchived"
			@agreement-status-updated="transactionDrawerState.onAgreementStatusUpdated"
			@agreement-updated="transactionDrawerState.onAgreementUpdated"
			@archived="transactionDrawerState.onArchived"
			@payment-sent="transactionDrawerState.onPaymentSent"
			@preview="transactionDrawerState.onPreviewMedia"
			@reviewed="transactionDrawerState.onReviewed"
			@status-updated="transactionDrawerState.onUpdateStatusSuccess"
			@task-updated="transactionDrawerState.onTaskUpdated"
			@updated="transactionDrawerState.onUpdated"
		/>
		<AgreementDrawer
			v-if="isAgreementDrawerRendered"
			v-model="agreementDrawerState.isOpen"
			:agreement-uuid="agreementDrawerState.activeAgreementUuid"
			@agreement-status-updated="agreementDrawerState.onUpdateStatusSuccess"
			@archived="agreementDrawerState.onArchived"
			@payment-sent="agreementDrawerState.onPaymentSent"
			@preview="agreementDrawerState.onPreviewMedia"
			@task-updated="agreementDrawerState.onTaskUpdated"
			@transaction-status-updated="agreementDrawerState.onTransactionStatusUpdated"
			@updated="agreementDrawerState.onUpdated"
		/>
		<BulletinApplicantProfileDrawer
			v-if="isBulletinApplicantProfileDrawerRendered"
			v-model="bulletinApplicantProfileDrawerState.isOpen"
			:applicant="bulletinApplicantProfileDrawerState.applicant"
			:bulletin="bulletinApplicantProfileDrawerState.bulletin"
			:hide-actions="bulletinApplicantProfileDrawerState.hideActions"
			@send-message="bulletinApplicantProfileDrawerState.onSendMessage"
			@propose-transaction="bulletinApplicantProfileDrawerState.onProposeTransaction"
		/>
		<SendMessageDialog
			v-if="isSendMessageDialogRendered"
			v-model="sendMessageDialogState.isOpen"
			:selected-users="sendMessageDialogState.selectedUsers"
			@success="sendMessageDialogState.onSuccess"
			@error="sendMessageDialogState.onError"
		/>
		<ExchangeTransactionPaymentDialog
			v-if="isExchangeTransactionPaymentDialogRendered"
			v-model="exchangeTransactionPaymentDialogState.isOpen"
			v-model:transactions="exchangeTransactionPaymentDialogState.transactions"
			:cost-breakdown="exchangeTransactionPaymentDialogState.costBreakdown"
			:transactions-store-id="exchangeTransactionPaymentDialogState.transactionsStoreId"
			@payment-sent="exchangeTransactionPaymentDialogState.onPaymentSent"
		/>
	</div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { whenever } from '@vueuse/core';
import { useMainStore } from '@/stores/mainStore';
import { useUserStore } from '@/stores/userStore';
import CommonBreadcrumbs from '@/components/CommonBreadcrumbs.vue';
import TheNavigationMenu from '@/components/TheNavigationMenu.vue';
import AppSwitcherTheme from '@/components/AppSwitcherTheme.vue';
import CommonContextMenu from '@/components/CommonContextMenu.vue';
import { type ContextMenuItem } from '@/components/CommonContextMenuItem.vue';
import {
	useImpersonationAuth,
	useCrudGalleryDrawer,
	useBulletinCrudModal,
	useTransactionProposeDialog,
	useAgreementDialog,
	useTransactionDrawer,
	useUserLists,
	useSendMessageDialog,
	useBreadcrumbs,
	useBulletinApplicantProfileDrawer,
	useExchangeTransactionPaymentDialog,
	usePageGroupTitle,
	useAgreementDrawer,
} from '@/composables';

const BulletinCrudModal = defineAsyncComponent(() => import('@/components/exchange/BulletinCrudModal.vue'));
const CrudGalleryDrawer = defineAsyncComponent(() => import('@/components/content-hub/CrudGalleryDrawer.vue'));
const AgreementDialog = defineAsyncComponent(() => import('@/components/AgreementDialog.vue'));
const TransactionProposeDialog = defineAsyncComponent(() => import('@/components/TransactionProposeDialog.vue'));
const TransactionDrawer = defineAsyncComponent(() => import('@/components/TransactionDrawer.vue'));
const AgreementDrawer = defineAsyncComponent(() => import('@/components/AgreementDrawer.vue'));
const BulletinApplicantProfileDrawer = defineAsyncComponent(() => import('@/components/exchange/BulletinApplicantProfileDrawer.vue'));
const ExchangeTransactionPaymentDialog = defineAsyncComponent(() => import('@/components/exchange/ExchangeTransactionPaymentDialog.vue'));

const props = defineProps<{
	options?: Record<string, unknown>;
}>();

const showAppBar = computed<boolean>(() => {
	if (typeof props.options?.hideAppBar === 'boolean') return !props.options.hideAppBar;
	return true;
});

const { t } = useI18n();
const { mobile } = useDisplay();
const userStore = useUserStore();
const mainStore = useMainStore();
const route = useRoute();
const { logOutImpersonation } = useImpersonationAuth();

const isNavigationMenuActive = ref(false);

const profileSwitchKey = ref(crypto.randomUUID());
watch(() => userStore.inflcrId, () => {
	if (userStore.profileCanAccessCurrentRoute && !route.meta.profileSwitchRedirect) {
		profileSwitchKey.value = crypto.randomUUID();
	}
});

// PAGE GROUP TITLE
const { state: pageGroupTitleState } = usePageGroupTitle();

// CRUD GALLERY DRAWER
const {
	state: crudGalleryDrawerState,
	open: openCrudGalleryDrawer,
	isRendered: isCrudGalleryDrawerRendered,
} = useCrudGalleryDrawer();

const {
	state: bulletinState,
	isRendered: isBulletinCrudModalRendered,
	open: openBulletinCrudModal,
} = useBulletinCrudModal();

const {
	breadcrumbItems,
	isBreadcrumbsVisible,
} = useBreadcrumbs();

const headerHeight = computed(() => {
	if (!mobile.value && isBreadcrumbsVisible.value) return 117;
	else if (route.name === 'NotFound' || route.name === 'NoAccess') return 77;
	else if (!mobile.value) return 81;
	return 60;
});

// EXCHANGE USER LIST CREATE DRAWER
const {
	state: exchangeUserListState,
	isRendered: isExchangeUserListCreateDrawerRendered,
	ExchangeUserListCreateDrawer,
} = useUserLists();

// AGREEMENT DIALOG
const {
	state: agreementDialogState,
	isRendered: isAgreementDialogRendered,
} = useAgreementDialog();

// TRANSACTION DIALOG
const {
	state: transactionState,
	isRendered: isTransactionProposeDialogRendered,
	open: openTransactionProposeDialog,
} = useTransactionProposeDialog({
	selectedAthletes: computed(() => {
		if (!userStore.profileIsAgent(userStore.profile)) return;
		return [userStore.profile.athlete.uuid];
	}),
});

// TRANSACTION DRAWER
const {
	state: transactionDrawerState,
	isRendered: isTransactionDrawerRendered,
} = useTransactionDrawer();

// AGREEMENT DRAWER
const {
	state: agreementDrawerState,
	isRendered: isAgreementDrawerRendered,
} = useAgreementDrawer();

// BULLETIN APPLICANT PROFILE DRAWER
const {
	state: bulletinApplicantProfileDrawerState,
	isRendered: isBulletinApplicantProfileDrawerRendered,
} = useBulletinApplicantProfileDrawer();
// SEND MESSAGE DIALOG
const {
	state: sendMessageDialogState,
	isRendered: isSendMessageDialogRendered,
	SendMessageDialog,
} = useSendMessageDialog();

// PAYMENT DIALOG
const {
	state: exchangeTransactionPaymentDialogState,
	isRendered: isExchangeTransactionPaymentDialogRendered,
} = useExchangeTransactionPaymentDialog();

const impersonatedByName = computed(() => {
	const lastInitial = userStore.impersonatedByUser?.last_name?.charAt(0);
	return `${userStore.impersonatedByUser?.first_name} ${lastInitial ? lastInitial + '.' : ''}`;
});
const quickActionsMenuItems = computed<ContextMenuItem[]>(() => {
	const items: ContextMenuItem[] = [
		{
			icon: 'image',
			label: t('newGallery'),
			action: () => openCrudGalleryDrawer(),
			requiredAccess: 'hasContentHubWrite',
		},
		{
			icon: 'local_atm',
			label: t('reportTransaction'),
			action: () => openTransactionProposeDialog(),
			requiredAccess: 'hasAgentWrite',
			visible: computed(() => !userStore.profileAccess.hasLeaderboardRead),
		},
		{
			icon: 'campaign',
			label: t('newBulletin'),
			action: () => openBulletinCrudModal(),
			requiredAccess: ['canAccessBulletins'],
			visible: computed(() => userStore.brandCanCreateBulletins && ['ExchangeBulletins', 'ExchangeBulletin', 'ExchangeBulletinDetails', 'ExchangeBulletinApplicants'].some((name) => route.name === name)),
		},
	];

	return items;
});

const { receivedConversationEvent } = storeToRefs(userStore);
whenever(receivedConversationEvent, (event) => {
	if (event.conversationUuid === route.params.conversationUuid) return;
	mainStore.setToast({
		type: 'info',
		title: t('toast.messageReceived.title', { name: event.senderName }),
		description: event.message.text,
		link: {
			name: 'ExchangeConversation',
			params: { conversationUuid: event.conversationUuid },
		},
	});
});
</script>

<style scoped lang="scss">
.layout-authenticated {
	height: 100%;
	&__app-bar{
		transition: background-color 0.2s;
	}
	&__app-bar-container {
		padding-top: 17px;

		&--rounded {
			border-top-left-radius: 16px;
		}
	}
	&__main {
		height: 100%;
	}
}
</style>
