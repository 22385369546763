import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import { handleResponse, handleError, type ApiErrorResponse } from './response';
import { useMock } from './mock';

if (import.meta.env.MODE !== 'production') useMock(axios);

export interface ListResponseMeta {
	current_page: number;
	from: number;
	last_page: number;
	path: string;
	per_page: number;
	to: number;
	total: number;
	next_cursor?: string | null;
}

export interface ListResponseMetaCursor {
	path: string;
	per_page: number;
	next_cursor: string | null;
	prev_cursor: string | null;
}

export interface ListReponseLinks {
	first: string;
	last: string;
	prev: string | null;
	next: string | null;
}
export interface ListResponse<T> {
	data: T[];
	meta: ListResponseMeta;
}

export interface ListResponseCursor<T> {
	data: T[];
	meta: ListResponseMetaCursor;
}

export interface CursorPaginationParams {
	cursor?: string;
	per_page?: number;
}

export type SortDirection = 'asc' | 'desc';

export interface SortParam<T> {
	key: T;
	order: SortDirection;
}

export type PaginationType = 'standard' | 'index' | 'cursor';

export type ComparisonOperator = '>' | '<' | '=' | '<=' | '>=';

export const PER_PAGE_OPTIONS = [25, 50, 100, 250] as const;
export type PerPageOption = typeof PER_PAGE_OPTIONS[number];
export const perPageOptions = [...PER_PAGE_OPTIONS];

export interface DateRange {
	start: string;
	end: string;
}

export const isApiError = (response: unknown): response is ApiErrorResponse => {
	return typeof response === 'object' && response !== null && 'message' in response;
};

const getRequestUrl = (resource: string, id?: string) => {
	const url = `/${resource}`;
	return id ? url + `/${id}` : url;
};

const getList = async <T = any, U = any>(
	resource: string,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.get<T>(getRequestUrl(resource), config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const getSingle = async <T = any, U = any>(
	resource: string,
	id: string,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.get<T>(getRequestUrl(resource, id), config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const post = async <T = any, U = any>(
	resource: string,
	model: Record<string, any>,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.post<T>(getRequestUrl(resource), model, config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const put = async <T = any, U = any>(
	resource: string,
	id: string | undefined,
	model: Record<string, any>,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.put<T>(getRequestUrl(resource, id), model, config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const patch = async <T = any, U = any>(
	resource: string,
	id: string,
	model?: Record<string, any>,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.patch<T>(getRequestUrl(resource, id), model, config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const patchWithoutId = async <T = any, U = any>(
	resource: string,
	model?: Record<string, any>,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.patch<T>(getRequestUrl(resource), model, config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const patchMany = async <T = any, U = any>(
	resource: string,
	model: Record<string, any>,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.patch<T>(getRequestUrl(resource), model, config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const remove = async <T = null, U = any>(
	resource: string,
	id: string,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.delete<T>(getRequestUrl(resource, id), config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};


const removeMany = async <T = any, U = any>(
	resource: string,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.delete<T>(getRequestUrl(resource), config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

export {
	getList,
	getSingle,
	post,
	put,
	patch,
	patchMany,
	patchWithoutId,
	remove,
	removeMany,
};
