import { i18n } from '@/plugins/i18n';

const PAYMENT_GATEWAYS = {
	STRIPE: 0,
	PAYPAL: 1,
	WALLET: 2,
} as const;

type PaymentGateway = typeof PAYMENT_GATEWAYS[keyof typeof PAYMENT_GATEWAYS];

const STRIPE_PAYMENT_METHOD_PAYMENT_TYPES = {
	CARD: 'card',
	ACH: 'ach',
} as const;

type StripePaymentMethodPaymentType = typeof STRIPE_PAYMENT_METHOD_PAYMENT_TYPES[keyof typeof STRIPE_PAYMENT_METHOD_PAYMENT_TYPES];

const VERIFIED_STATUSES = {
	PENDING: 0,
	TRY_AGAIN: 20,
	FAILED: 30,
	VERIFIED: 50,
} as const;

type VerifiedStatus = typeof VERIFIED_STATUSES[keyof typeof VERIFIED_STATUSES];

interface VerifyPaymentMethodRequestModel {
	amounts: [number, number];
}

interface PostVerifyPaymentMethodResponse {
	data: {
		verified_status: VerifiedStatus;
	};
}

interface CreatePaymentMethodRequestModel {
	label: string;
	is_default?: boolean;
	payment_type: StripePaymentMethodPaymentType;
	stripe_setup_intend_id?: string;
	stripe_token_id?: string;
}

interface ApiExchangePaymentMethodStripe {
	uuid: string;
	brand: string;
	label: string;
	is_default: boolean;
	verified_status: VerifiedStatus;
	payment_type: StripePaymentMethodPaymentType;
	is_expired: boolean;
	expiration_date: string | null;
	gateway: 0;
	created_at: string;
	updated_at: string;
}

const WALLET_ACCOUNT_TYPES = {
	CASH: 'CASH',
	EARN: 'EARN',
	EXTERNAL: 'EXTERNAL',
	TAX_RESERVES: 'TAX_RESERVES',
	TPA_PENDING: 'TPA_PENDING',
} as const;

type WalletAccountType = typeof WALLET_ACCOUNT_TYPES[keyof typeof WALLET_ACCOUNT_TYPES];

interface ApiExchangePaymentMethodWallet {
	id: string;
	type: WalletAccountType;
	nickname: string | null;
	balance: number;
	gateway: 2;
	status: string;
	is_default: boolean;
	business_name: string;
}

const isApiExchangePaymentMethodStripe = (
	data: ApiExchangePaymentMethodStripe | ApiExchangePaymentMethodWallet,
): data is ApiExchangePaymentMethodStripe => {
	return data.gateway === 0;
};

const isApiExchangePaymentMethodWallet = (
	data: ApiExchangePaymentMethodStripe | ApiExchangePaymentMethodWallet,
): data is ApiExchangePaymentMethodWallet => {
	return data.gateway === 2;
};

interface UpdatePaymentMethodRequestModel {
	label?: string;
	is_default?: boolean;
}

class BaseExchangePaymentMethod {
	id: string;
	label: string;
	gateway: PaymentGateway;
	isDefault: boolean;

	isStripe(): this is ExchangePaymentMethodStripe {
		return this instanceof ExchangePaymentMethodStripe;
	}

	isWallet(): this is ExchangePaymentMethodWallet {
		return this instanceof ExchangePaymentMethodWallet;
	}

	constructor(data: ApiExchangePaymentMethodStripe | ApiExchangePaymentMethodWallet) {
		this.gateway = data.gateway;

		if (isApiExchangePaymentMethodStripe(data)) {
			this.id = data.uuid;
			this.label = data.label;
		} else if (isApiExchangePaymentMethodWallet(data)) {
			this.id = data.id;
			const accountName = data.nickname ?? `${i18n.global.t('paymentGateways.wallet')} ${i18n.global.t('account')}`;
			this.label = `${accountName} (${data.business_name})`;
		}
	}

	get brandIcon(): string {
		return '';
	}

	get brandIconColor(): string | undefined {
		return undefined;
	}
}

class ExchangePaymentMethodStripe extends BaseExchangePaymentMethod {
	isDefault: boolean;
	brand: string;
	verifiedStatus: VerifiedStatus;
	paymentType: StripePaymentMethodPaymentType;
	isExpired: boolean;
	expirationDate: string | null;

	constructor(data: ApiExchangePaymentMethodStripe) {
		super(data);
		this.isDefault = data.is_default;
		this.brand = data.brand;
		this.verifiedStatus = data.verified_status;
		this.paymentType = data.payment_type;
		this.isExpired = data.is_expired;
		this.expirationDate = data.expiration_date;
	}

	get brandIcon() {
		if (this.paymentType === STRIPE_PAYMENT_METHOD_PAYMENT_TYPES.ACH) {
			return '$twi-bank-transfer';
		}

		switch (this.brand) {
			case 'visa':
				return '$twi-visa';
			case 'mastercard':
				return '$twi-mastercard';
			case 'amex':
				return '$twi-amex';
			case 'discover':
				return '$twi-discover';
			case 'diners':
				return '$twi-diners';
			case 'jcb':
				return '$twi-jcb';
			default:
				return 'credit_card';
		}
	}

	get brandIconColor() {
		if (this.paymentType === STRIPE_PAYMENT_METHOD_PAYMENT_TYPES.ACH) {
			return undefined;
		}
		switch (this.brand) {
			case 'visa':
				return 'brands-visa';
			case 'mastercard':
				return 'brands-mastercard';
			case 'amex':
				return 'brands-amex';
			case 'discover':
				return 'brands-discover';
			case 'diners':
				return 'brands-diners';
			case 'jcb':
				return 'brands-jcb';
			default:
				return undefined;
		}
	}
}

class ExchangePaymentMethodWallet extends BaseExchangePaymentMethod {
	balance: number;
	accountType: WalletAccountType;
	isDisabled: boolean;
	isDefault: boolean;

	constructor(data: ApiExchangePaymentMethodWallet) {
		super(data);
		this.balance = data.balance;
		this.accountType = data.type;
		this.isDisabled = data.type === 'TPA_PENDING' || data.status !== 'ACTIVE';
		this.isDefault = data.is_default;
	}

	get brandIcon(): string {
		return '$twi-bank-transfer';
	}

	get brandIconColor(): string | undefined {
		return 'black';
	}
}

interface ApiStripeOnboarding {
	object: string;
	created: number;
	expires_at: number;
	url: string;
}

interface GetExchangePaymentMethodsResponse {
	accounts: (ApiExchangePaymentMethodStripe | ApiExchangePaymentMethodWallet)[];
	onboarding: {
		stripe: ApiStripeOnboarding | null;
		wallet: string | null;
	};
}

interface PaymentDefault {
	gateway: PaymentGateway;
	payment_method: string;
}

interface GetExchangePaymentMethodsStripeResponse {
	data: {
		accounts: ApiExchangePaymentMethodStripe[];
		onboarding: {
			stripe: ApiStripeOnboarding | null;
			wallet: null;
		};
	};
}

interface GetExchangePaymentMethodsWalletResponse {
	data: {
		accounts: ApiExchangePaymentMethodWallet[];
		onboarding: {
			stripe: null;
			wallet: ExchangePaymentMethodOnboarding;
		};
	};
}

export interface ExchangePaymentMethodOnboarding {
	link: string;
	status: boolean;
}

interface ExchangePaymentMethodResponseSingle {
	data: ApiExchangePaymentMethodStripe;
}

interface ClientSecretResponse {
	data: {
		client_secret: string;
	};
}

type PaymentDefaultResponse = number;

export {
	PAYMENT_GATEWAYS,
	STRIPE_PAYMENT_METHOD_PAYMENT_TYPES,
	VERIFIED_STATUSES,
	WALLET_ACCOUNT_TYPES,
	BaseExchangePaymentMethod,
	ExchangePaymentMethodStripe,
	ExchangePaymentMethodWallet,
	isApiExchangePaymentMethodStripe,
	isApiExchangePaymentMethodWallet,
	type ApiExchangePaymentMethodStripe,
	type PaymentGateway,
	type StripePaymentMethodPaymentType,
	type VerifiedStatus,
	type VerifyPaymentMethodRequestModel,
	type PostVerifyPaymentMethodResponse,
	type CreatePaymentMethodRequestModel,
	type UpdatePaymentMethodRequestModel,
	type GetExchangePaymentMethodsResponse,
	type GetExchangePaymentMethodsStripeResponse,
	type GetExchangePaymentMethodsWalletResponse,
	type ExchangePaymentMethodResponseSingle,
	type ClientSecretResponse,
	type PaymentDefault,
	type PaymentDefaultResponse,
};
