export const APP_FLAGS = {} as const;
export type AppFlag = typeof APP_FLAGS[keyof typeof APP_FLAGS];

export const SCHOOL_FLAGS = {
	WALLET_ENABLED: 'Wallet Beta',
} as const;
export type SchoolFlag = typeof SCHOOL_FLAGS[keyof typeof SCHOOL_FLAGS];

export const TEAM_FLAGS = {} as const;
export type TeamFlag = typeof TEAM_FLAGS[keyof typeof TEAM_FLAGS];

export const USER_FLAGS = {} as const;
export type UserFlag = typeof USER_FLAGS[keyof typeof USER_FLAGS];

export const BRAND_FLAGS = {} as const;
export type BrandFlag = typeof BRAND_FLAGS[keyof typeof BRAND_FLAGS];

export type AnyFlag = SchoolFlag | AppFlag | TeamFlag | UserFlag | BrandFlag;

export interface ApiFeatureFlags {
	data: {
		app: [
			{
				identifier: null;
				flags: Record<AppFlag, boolean>;
			},
		];
		school: {
			identifier: string;
			flags: Record<SchoolFlag, boolean>;
		}[];
		team: {
			identifier: string;
			flags: Record<TeamFlag, boolean>;
		}[];
		user: [
			{
				identifier: null;
				flags: Record<UserFlag, boolean>;
			},
		];
		brand:
		{
			identifier: string;
			flags: Record<BrandFlag, boolean>;
		}[];

	};
}

export interface FeatureFlag {
	slug: string;
	value: boolean;
}
