import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
	{
		path: '/disclose-nil-activity',
		name: 'DiscloseNilActivity',
		component: () => import('@/views/DiscloseNilActivity.vue'),
		meta: {
			layout: 'LayoutAuthenticated',
			requiresAuth: true,
			title: 'pageDiscloseNilActivity.metaTitle',
			requiredAccess: 'hasTransactionsImportWrite',
		},
	},
	{
		path: '/ncaa-service-providers',
		name: 'ServiceProvidersDashboard',
		component: () => import('@/views/ServiceProvidersDashboard.vue'),
		meta: {
			layout: 'LayoutAuthenticated',
			requiresAuth: true,
			title: 'pageServiceProvidersDashboard.metaTitle',
			requiredAccess: 'hasTransactionsImportWrite',
		},
	},
];

export default routes;
