import {
	parseISO,
	isWithinInterval,
	sub,
	formatDistanceToNow,
	formatDate,
} from 'date-fns';

/**
 * Formats a date string or an object with date and options.
 * @param value - A string date or an object containing the date and formatting options.
 * @returns The formatted date string.
 */
export function formatDateTime(
	value: string | { date: string; pattern?: string; humanReadable?: boolean; showTimeZone?: boolean },
): string {
	const date = typeof value === 'string' ? parseISO(value) : parseISO(value.date);
	const pattern = typeof value === 'string' ? 'PP' : value.pattern ?? 'PP';
	const humanReadable = typeof value === 'string' ? false : value.humanReadable ?? false;
	const showTimeZone = typeof value === 'string' ? false : value.showTimeZone ?? false;

	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	if (humanReadable) {
		return isWithinInterval(date, {
			start: sub(new Date(), { weeks: 1 }),
			end: new Date(),
		})
			? formatDistanceToNow(date, { addSuffix: true })
			: formatWithOptionalTimeZone(date, pattern, showTimeZone, timeZone);
	} else {
		return formatWithOptionalTimeZone(date, pattern, showTimeZone, timeZone);
	}
}

/**
   * Formats a date with an optional time zone.
   * @param date - The date to format.
   * @param pattern - The format pattern to use.
   * @param showTimeZone - Whether to include the time zone in the formatted string.
   * @param timeZone - The time zone to use.
   * @returns The formatted date string.
   */
function formatWithOptionalTimeZone(date: Date, pattern: string, showTimeZone: boolean, timeZone: string): string {
	// return if date is invalid
	if (isNaN(date.getTime())) return '';
	const formattedDate = formatDate(date, pattern);
	if (showTimeZone) {
		const timeZoneAbbr = getTimeZoneAbbr(date, timeZone);
		return `${formattedDate} ${timeZoneAbbr}`;
	}
	return formattedDate;
}

/**
   * Gets the time zone abbreviation for a date and time zone.
   * @param date - The date to get the time zone abbreviation for.
   * @param timeZone - The time zone to use.
   * @returns The time zone abbreviation.
   */
function getTimeZoneAbbr(date: Date, timeZone: string): string {
	const options = { timeZone, timeZoneName: 'short' };
	const formatter = new Intl.DateTimeFormat('en-US', { ...options, timeZoneName: 'short' });
	const parts = formatter.formatToParts(date);
	const timeZoneName = parts.find((part) => part.type === 'timeZoneName');
	return timeZoneName ? timeZoneName.value : '';
}

export function getIsoDateString(date: string | null | undefined) {
	return date?.split('T')[0];
}
