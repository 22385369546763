import type {
	CursorPaginationParams,
	ListResponseCursor,
} from '@/api/utilities/provider';
import {
	BaseTransaction,
	type ApiBaseTransaction,
	type ApiVendor,
	type ExchangeStatus,
	type TransactionPaymentType,
	type TransactionType,
	type TransactionUnitType,
	type Vendor,
	type TasksOverview,
} from '@/types/transactions';
import type { ApiMediaAttachment, Media } from '@/types/media';
import type { MessageModelAgreement } from '@/types/conversations';
import { type ApiTask, Task } from '@/types/tasks';
import { getIsoDateString } from '@/utils/dates';

const AGREEMENT_STATUSES = {
	PROPOSED: 0,
	DECLINED: 1,
	APPROVED: 2,
	EXPIRED: 3,
	ARCHIVED: 4,
} as const;

type AgreementStatus = typeof AGREEMENT_STATUSES[keyof typeof AGREEMENT_STATUSES];

type UserUpdateableAgreementStatus = typeof AGREEMENT_STATUSES.APPROVED | typeof AGREEMENT_STATUSES.DECLINED;

interface ApiAgreementTransaction {
	amount: number;
	approval_required: boolean;
	display_order: number;
	due_at: string;
	exchange_status: ExchangeStatus;
	name: string;
	uuid: Uuid;
}

interface ApiAgreement {
	amount: number;
	attachments: Media[];
	category: TransactionType;
	created_at: string;
	description: string;
	expires_at: string | null;
	approval_required: boolean;
	name: string;
	payee: {
		name: string;
		uuid: Uuid;
	};
	payment_type: TransactionPaymentType;
	status: AgreementStatus;
	tasks: ApiTask[];
	transactions: ApiAgreementTransaction[];
	transactions_count: number;
	unit_type: TransactionUnitType;
	updated_at: string;
	uuid: Uuid;
	vendor: ApiVendor;
}

type ApiListAgreement = Pick<
	ApiAgreement,
	| 'amount'
	| 'name'
	| 'payee'
	| 'status'
	| 'transactions_count'
	| 'updated_at'
	| 'uuid'
>;

type GetAgreementsResponse = ListResponseCursor<ApiListAgreement>;

interface GetAgreementsRequestParams extends CursorPaginationParams {
	name?: string;
}

type ApiRecentAgreement = Pick<
	ApiAgreement,
	| 'amount'
	| 'category'
	| 'name'
	| 'status'
	| 'transactions_count'
	| 'unit_type'
	| 'updated_at'
	| 'uuid'
>;

type GetAgreementResponse = ApiAgreement;

type GetRecentAgreementsResponse = ListResponseCursor<ApiRecentAgreement>;

type GetRecentAgreementsRequestParams = CursorPaginationParams;

interface CreateAgreementRequestModel {
	attachments?: ApiMediaAttachment[];
	category: TransactionType;
	description: string;
	expires_at?: string;
	name: string;
	payees: Uuid[];
	payment_type: TransactionPaymentType;
	tasks?: {
		name: string;
		description: string;
		due_at?: string;
		attachments?: ApiMediaAttachment[];
	}[];
	transactions: {
		amount: number;
		display_order: number;
		due_at: string;
		name: string;
	}[];
	unit_type: TransactionUnitType;
	vendor: ApiVendor;
}

type CreateAgreementRequestModelTask = NonNullable<CreateAgreementRequestModel['tasks']>[number];

type UpdateAgreementRequestModel = Partial<Omit<
	CreateAgreementRequestModel,
	| 'payees'
	| 'transactions'
> & {
	status: UserUpdateableAgreementStatus;
}>;

interface BulkUpdateAgreementStatusRequestModel {
	agreements: Uuid[];
	status: UserUpdateableAgreementStatus;
}

type ReplaceAgreementTransactionsRequestModel = Pick<CreateAgreementRequestModel, 'transactions'>;

class BaseAgreement {
	amount: number;
	name: string;
	status: AgreementStatus;
	transactionsCount: number;
	uuid: Uuid;

	constructor(data:
		| ApiAgreement
		| ApiListAgreement
		| ApiRecentAgreement
		| NonNullable<ApiBaseTransaction['agreement']>,
	) {
		this.amount = data.amount;
		this.name = data.name;
		this.status = data.status;
		this.transactionsCount = data.transactions_count;
		this.uuid = data.uuid;
	}
}

class ListAgreement extends BaseAgreement {
	payeeName: string;
	updatedAt: string;

	constructor(data: ApiListAgreement) {
		super(data);
		this.payeeName = data.payee.name;
		this.updatedAt = data.updated_at;
	}
}

class Agreement extends BaseAgreement {
	attachments: Media[];
	category: TransactionType;
	createdAt: string;
	description: string;
	expiresAt: string | null;
	approvalRequired: boolean;
	payeeName: string;
	payeeUuid: Uuid;
	tasks: Task[];
	tasksOverview: TasksOverview;
	transactions: BaseTransaction[];
	updatedAt: string;
	vendor: Vendor;
	unitType: TransactionUnitType;
	paymentType: TransactionPaymentType;

	constructor(data: ApiAgreement) {
		super(data);
		this.attachments = data.attachments;
		this.category = data.category;
		this.createdAt = data.created_at;
		this.description = data.description;
		this.expiresAt = getIsoDateString(data.expires_at) ?? null;
		this.approvalRequired = data.approval_required;
		this.payeeName = data.payee.name;
		this.payeeUuid = data.payee.uuid;
		this.paymentType = data.payment_type;
		this.tasks = data.tasks.map((task) => new Task(task));

		this.tasksOverview = {
			completed: 0,
			total: 0,
			percentage: null,
		};

		if (data.tasks.length) {
			const completedTasks = data.tasks.filter((task) => task.completed_at !== null).length;
			const totalTasks = data.tasks.length;
			this.tasksOverview = {
				completed: completedTasks,
				total: totalTasks,
				percentage: completedTasks / totalTasks,
			};
		}

		this.transactions = data.transactions.map((transaction) => new BaseTransaction(transaction));
		this.updatedAt = data.updated_at;
		this.vendor = {
			email: data.vendor.email,
			name: data.vendor.name,
			phone: data.vendor.phone,
			pointOfContact: data.vendor.point_of_contact,
		};
		this.unitType = data.unit_type;
	}
}

class RecentAgreement extends BaseAgreement {
	category: TransactionType;

	constructor(data: ApiRecentAgreement | MessageModelAgreement) {
		super(data);
		this.category = data.category;
	}
}

export {
	AGREEMENT_STATUSES,
	type AgreementStatus,
	type UserUpdateableAgreementStatus,
	type ApiAgreementTransaction,
	type ApiListAgreement,
	type GetAgreementsResponse,
	type GetAgreementsRequestParams,
	type ApiAgreement,
	type GetAgreementResponse,
	type ApiRecentAgreement,
	type GetRecentAgreementsResponse,
	type GetRecentAgreementsRequestParams,
	type CreateAgreementRequestModel,
	type CreateAgreementRequestModelTask,
	type UpdateAgreementRequestModel,
	type BulkUpdateAgreementStatusRequestModel,
	type ReplaceAgreementTransactionsRequestModel,
	BaseAgreement,
	ListAgreement,
	Agreement,
	RecentAgreement,
};
